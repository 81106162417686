<template>
    
    <div class="h-auto rounded-lg bg-filters py-2 px-2 mb-2">
        <div class="h-8 flex flex-row justify-start items-center">
            <div class="h-6 w-1 rounded-full mr-2" :style="'background-color:' + legendColors[row.id].color + ';'"></div>
            <span class="text-white font-semibold text-xs">{{ row.name }}</span>
            <div class="h-6 w-6 rounded-md flex flex-col justify-center items-center cursor-pointer ml-auto" :class="getOpacity(row.id)" @click="toggleSlSelectedItems(row.id)">
                <i class="mdi mdi-ray-start-arrow text-xs"></i>
            </div>
        </div>
        <div class="h-auto mt-1 flex flex-row justify-between items-center">
            <span class="text-xxs text-font-gray font-semibold">ANSR</span>
            <span class="text-xxs text-white font-semibold">
                {{ row.ner || 0 | reduceBigNumbers(2) }}€
            </span>
        </div>
        <div class="h-8 flex flex-col">
            <div class="flex-1 flex flex-row justify-start items-center">
                <span class="text-3xs text-font-gray mr-1">vs Obj.</span>
                <span class="text-3xs text-white font-semibold mr-auto">
                    <span v-if="row.ner_vs_objective>0">+</span>{{ row.ner_vs_objective || 0 | numberFormat(2) }}<span v-if=" row.ner_vs_objective!='TBD'">%</span>
                </span>
                <span class="text-3xs text-font-gray mr-1">Unbilled</span>
                <span class="text-3xs text-white font-semibold mr-auto">
                    {{ row.unbilled || 0 | reduceBigNumbers(2) }}€
                </span>
                <span class="text-3xs text-font-gray mr-1">PMC</span>
                <span class="text-3xs text-white font-semibold">
                    {{ row.pm_payment || 0 | reduceBigNumbers(0) }}d
                </span>
            </div>
            <div class="rounded-full h-2px bg-progress overflow-hidden relative">
              <div class="rounded-full bg-success h-full absolute left-0" v-if="row.ner_vs_objective!='TBD'"
                   :style="'width:' + row.ner_vs_objective + '%;'">
              </div>
              <div class="rounded-full bg-success h-full absolute left-0" v-else
                   :style="'width:100%;'">
              </div>
              <div class="rounded-full bg-success h-full absolute left-0" v-if="row.objective_sale!='TBD'"
                   :style="'width:' + row.objective_sale + '%;'">
              </div>
              <div class="rounded-full bg-success h-full absolute left-0" v-else
                   :style="'width:100%;'">
              </div>
            </div>
        </div>
        <div class="h-auto mt-2 flex flex-row justify-between items-center">
            <span class="text-xxs text-font-gray font-semibold mr-auto">Margin</span>
            <span class="text-xxs text-white font-semibold">
                <span v-if="row.margin > 0">+</span>{{ row.margin || 0 | numberFormat(2) }}%
            </span>
        </div>
        <div class="h-8 flex flex-col">
            <div class="flex-1 flex flex-row justify-start items-center">
                <span class="text-3xs text-font-gray mr-1">vs Obj.</span>
                <span class="text-3xs text-white font-semibold mr-auto">
                    <span v-if="row.margin_vs_objective>0">+</span>{{ row.margin_vs_objective || 0 | numberFormat(2) }}<span v-if=" row.margin_vs_objective!='TBD'">%</span>
                </span>
                <span v-if="row.TNR != 'N/A'" class="text-3xs text-font-gray mr-1">TNR</span>
                <span v-if="row.TNR != 'N/A'" class="text-3xs text-white font-semibold">
                    {{ row.TNR || 0 | reduceBigNumbers(2) }}€
                </span>
               <!-- <span class="text-3xs text-font-gray mr-1">vs Plan</span>
               <span class="text-3xs text-white font-semibold">
                   {{ row.vs_plan || 0 | reduceBigNumbers(2) }}
               </span> -->
            </div>
            <div class="rounded-full h-2px bg-progress flex overflow-hidden">
              <div class="rounded-full bg-warning h-full" v-if="row.margin_vs_objective!='TBD'"
                   :style="'width:' + row.margin_vs_objective + '%;'">
              </div>
              <div class="rounded-full bg-warning h-full" v-else
                   :style="'width:100%;'">
              </div>
            </div>
        </div>
    </div>

</template>

<script>
import { state, actions } from '@/store';

    export default {
        name: 'ProjectsSLMobile',
        props: ['row'],
        computed:{
            legendColors() { 
                return state.slLegendColors 
            },
            slSelectedItems(){
                return state.slSelectedItems
            }
        },
        methods:{
            applySL(sl){
                this.$emit('applySL',sl)
            },
            getOpacity(k) {

                    if (this.slSelectedItems.includes(k)) {
                    return 'bg-aux-15 text-aux'
                } else {
                    return 'bg-font-gray-15 text-font-gray'
                }

            },
            toggleSlSelectedItems(item) { 


                if(!this.slSelectedItems.includes(item)){
                    actions.toggleSlSelectedItems(item)
                    this.$emit('nextPage')    
                } else {
                    actions.toggleSlSelectedItems(item)
                }
            }
        }
    }
</script>